(function($) {
  if($('li.menu-veilingen').hasClass('active')) {
    $('span.breadcrumb_last').html('Veilingen');
  }

  $('.woocommerce-terms-and-conditions-link').attr('target', '_blank');
  $('body').on('click', '.woocommerce-terms-and-conditions-link', function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    window.open(href, '_blank');
  });
})(jQuery);
